import createapp from '@/common';

import Track from '@/utils/Track';

const paths = window.location.href.split('/');
const clear = Track({
  ea: 'open',
  ec: 'work', //name
  el: paths[paths.length - 1],
});
const app = createapp();

app.mount('#app');
